import React from "react";
import "../../styles/components/DocumentLoader/style.scss";
function Loader() {
  return (
    <>
      <div id="preloader-active">
        <div className="docloader">
          <div className="title">
            PROCESSING DOCUMENTS
          </div>
          <div className="preloader-inner position-relative">
            <div className="preloaderCircle" />
            <div className="preloaderImg">
              <img
                src="https://images.prismic.io/opalvest/9dbd1681-9157-4583-ada6-ead84f704ca8_login.png?auto=compress,format"
                className="preloadImg"
                alt=""
              />
            </div>
          </div>
          <div className="label-wait">
            Please wait
          </div>
          <div className="">
            This step could take a moment or two
          </div>
          <div className="description">
            Do not close or navigate away from your browser until this process completes.
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
